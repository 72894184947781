import { BaseController } from "../../utilities/base_controller";
import { getOtherRadiosInGroup, isElementCheckable, isHTMLInputElement, isHTMLSelectElement } from "../../utilities/elements";
import { useEventListener } from "../../mixins/use_event_listener";
export class DetectDirtyFormController extends BaseController {
    get _formElements() {
        return Array.from(this.el.querySelectorAll('input, select, textarea'));
    }
    get _cacheAttrName() {
        return 'detect-dirty-load-value';
    }
    connect() {
        let element = this.el;
        this._cacheLoadValues();
        this._checkDirty();
        useEventListener(this, element, ["input", "change"], this._checkDirty, { debounce: 10 });
    }
    restore(event) {
        event?.preventDefault();
        this._formElements.forEach(element => this._restoreElementFromLoadValue(element));
    }
    _getElementValue(element) {
        return isElementCheckable(element) ? element.checked : element.value;
    }
    _getElementLoadValue(element) {
        let value = element.getAttribute(this._cacheAttrName);
        if (isElementCheckable(element)) {
            return value == null ? element.defaultChecked : value == "true";
        }
        else if (isHTMLSelectElement(element)) {
            let options = Array.from(element.options);
            options.forEach((option) => {
                if (option.defaultSelected) {
                    element.value = option.value;
                    return option.value;
                }
            });
        }
        else if (value !== null) {
            return value;
        }
        return value;
    }
    _elementHasCachedLoadValue(element) {
        return element.hasAttribute(this._cacheAttrName);
    }
    _checkElementDirty(element) {
        if (isHTMLInputElement(element) && element.type == "radio") {
            getOtherRadiosInGroup(element).forEach((radio) => radio.removeAttribute('data-dirty'));
        }
        if (this._isElementDirty(element)) {
            element.setAttribute('data-dirty', "true");
        }
        else {
            element.removeAttribute('data-dirty');
        }
    }
    _isElementDirty(element) {
        return this._getElementValue(element) !== this._getElementLoadValue(element);
    }
    _cacheElementLoadValue(element) {
        if (!this._elementHasCachedLoadValue(element) && isElementCheckable(element)) {
            element.setAttribute(this._cacheAttrName, element.checked.toString());
        }
        else {
            element.setAttribute(this._cacheAttrName, element.value.toString());
        }
    }
    _restoreElementFromLoadValue(element) {
        let cacheValue = element.getAttribute(this._cacheAttrName);
        if (isElementCheckable(element)) {
            element.setAttribute(this._cacheAttrName, element.checked.toString());
            element.checked = cacheValue == null ? element.defaultChecked : cacheValue == "true";
        }
        else if (isHTMLSelectElement(element)) {
            if (cacheValue == null) {
                let options = Array.from(element.options);
                options.forEach((option) => {
                    if (option.defaultSelected) {
                        element.value = option.value;
                        return;
                    }
                });
            }
            else {
                element.value = cacheValue;
            }
        }
        else {
            element.value = cacheValue == null ? element.defaultValue : cacheValue;
        }
    }
    _cacheLoadValues() {
        this._formElements.forEach(el => this._cacheElementLoadValue(el));
    }
    _checkDirty() {
        this._formElements.forEach(el => this._checkElementDirty(el));
        if (this._formElements.some(el => isFormDirty(el))) {
            this.el.setAttribute("data-dirty", "true");
        }
        else {
            this.el.removeAttribute("data-dirty");
        }
    }
}
export function isFormDirty(element) {
    return element.hasAttribute("data-dirty");
}
