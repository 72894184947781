import { BaseController } from "../utilities/base_controller";
export class ClipboardController extends BaseController {
    static targets = ["source", "button", "copy", "fallback"];
    static values = { removeUnused: Boolean };
    _supported = false;
    connect() {
        this._supported = document.queryCommandSupported("copy");
        if (this.hasRemoveUnusedValue && this.removeUnusedValue) {
            if (this._supported && this.hasFallbackTarget) {
                this.fallbackTarget.remove();
            }
            else if (this.hasCopyTarget) {
                this.copyTarget.remove();
            }
        }
    }
    select(event) {
        if (event) {
            event.preventDefault();
        }
        this.sourceTarget.select();
    }
    copy(event) {
        if (event) {
            event.preventDefault();
        }
        this.sourceTarget.select();
        if (this._supported) {
            document.execCommand("copy");
        }
    }
}
