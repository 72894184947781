import { useMutationObserver } from "../mixins/use_mutation_observer";
import { BaseController } from "../utilities/base_controller";
import { installClassMethods } from "../mixins/install_class_methods";
export class EmptyDomController extends BaseController {
    static targets = ["container"];
    static classes = ["empty", "notEmpty"];
    static values = { scopeSelector: String };
    get _container() {
        return this.hasContainerTarget ? this.containerTarget : this.el;
    }
    get _children() {
        let element = this._container;
        if (this.hasScopeSelectorValue) {
            return Array.from(element.querySelectorAll(this.scopeSelectorValue));
        }
        else {
            return Array.from(element.children);
        }
    }
    connect() {
        installClassMethods(this);
        useMutationObserver(this, this._container, this.mutate, { childList: true });
        this.checkEmpty();
    }
    mutate(entries) {
        this.checkEmpty();
    }
    checkEmpty() {
        let element = this._container;
        let children = this._children;
        if (children.length === 0) {
            this.removeNotEmptyClasses();
            this.addEmptyClasses();
            this.dispatchEvent(element, "dom:empty");
        }
        else {
            this.addNotEmptyClasses();
            this.removeEmptyClasses();
            this.dispatchEvent(element, "dom:not-empty", { detail: { count: children.length } });
        }
    }
}
