import { BaseController } from "../../utilities/base_controller";
import { scrollAbsoluteBottom, scrollAbsoluteLeft, scrollAbsoluteTop, scrollDown, scrollLeft, scrollRight, scrollUp } from "../../utilities/scroll";
export class ScrollContainerController extends BaseController {
    static values = {
        behaviour: String,
        increment: Number,
    };
    get _increment() {
        return this.hasIncrementValue ? this.incrementValue : 50;
    }
    get _behaviour() {
        if (this.hasBehaviourValue) {
            if (["auto", "smooth"].includes(this.behaviourValue)) {
                return this.behaviourValue;
            }
            else {
                throw new Error(`'${this.behaviourValue}' is not a recognised scroll behaviour`);
            }
        }
        else {
            return "auto";
        }
    }
    async scrollTop(event) {
        event?.preventDefault();
        await scrollAbsoluteTop(this.el, { behavior: this._behaviour });
    }
    async scrollBottom(event) {
        event?.preventDefault();
        await scrollAbsoluteBottom(this.el, { behavior: this._behaviour });
    }
    async scrollLeft(event) {
        event?.preventDefault();
        await scrollAbsoluteLeft(this.el, { behavior: this._behaviour });
    }
    async scrollRight(event) {
        event?.preventDefault();
        await scrollAbsoluteLeft(this.el, { behavior: this._behaviour });
    }
    async up(event) {
        event?.preventDefault();
        await scrollUp(this.el, this._increment, { behavior: this._behaviour });
    }
    async down(event) {
        event?.preventDefault();
        await scrollDown(this.el, this._increment, { behavior: this._behaviour });
    }
    async left(event) {
        event?.preventDefault();
        await scrollLeft(this.el, this._increment, { behavior: this._behaviour });
    }
    async right(event) {
        event?.preventDefault();
        await scrollRight(this.el, this._increment, { behavior: this._behaviour });
    }
}
