import { BaseController } from '../../utilities/base_controller';
import { useCollectionEventListener, useEventListener } from "../../mixins/use_event_listener";
export class CheckboxSelectAllController extends BaseController {
    static targets = ['selectAll', 'checkbox'];
    get _checked() {
        return this._enabled.filter(checkbox => checkbox.checked);
    }
    get _enabled() {
        return this.checkboxTargets.filter(checkbox => !checkbox.disabled && !checkbox.readOnly);
    }
    get _unchecked() {
        return this._enabled.filter(checkbox => !checkbox.checked);
    }
    connect() {
        requestAnimationFrame(() => {
            if (!this.hasSelectAllTarget) {
                return;
            }
            useEventListener(this, this.selectAllTarget, 'change', this._toggle);
            useCollectionEventListener(this, this.checkboxTargets, 'change', this._refresh);
            this._refresh();
        });
    }
    _toggle(event) {
        event.preventDefault();
        let target = event.target;
        this._enabled.forEach((checkbox) => checkbox.checked = (checkbox.disabled || checkbox.readOnly) ? checkbox.checked : target.checked);
    }
    _refresh() {
        const checkboxesCount = this._enabled.length;
        const checkboxesCheckedCount = this._checked.length;
        this.selectAllTarget.checked = checkboxesCheckedCount > 0;
        this.selectAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
    }
}
