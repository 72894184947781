import { Controller } from "@hotwired/stimulus";
import { log, logProperty } from "./logging";
import { dispatchEvent } from "./events";
export class BaseController extends Controller {
    constructor(context) {
        super(context);
        // @ts-ignore
        if (!this.application.debug) {
            return this;
        }
        return new Proxy(this, {
            get: (obj, prop) => {
                let returnVal = Reflect.get(obj, prop);
                let self = this;
                if ("logFormattedMessage" in this.application) {
                    return returnVal;
                }
                if (logProperty(prop.toString())) {
                    if (typeof returnVal == "function") {
                        return new Proxy(returnVal, {
                            apply(target, thisArg, argArray) {
                                log(self, prop.toString(), {
                                    args: argArray,
                                });
                                return Reflect.apply(target, thisArg, argArray);
                            },
                        });
                    }
                    else {
                        log(this, prop.toString());
                    }
                }
                return returnVal;
            },
        });
    }
    get el() {
        return this.element;
    }
    get isTurboPreview() {
        return document.documentElement.hasAttribute('data-turbo-preview') || document.documentElement.hasAttribute('data-turbolinks-preview');
    }
    get isTurbolinksPreview() {
        return this.isTurboPreview;
    }
    get csrfToken() {
        return this.metaValue('csrf-token');
    }
    metaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`);
        return element?.getAttribute('content') || null;
    }
    eventName(eventName) {
        return `${this.identifier}:${eventName}`;
    }
    dispatchEvent(element, eventName, options = {}) {
        dispatchEvent(this, element, eventName, options);
    }
}
