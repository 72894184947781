import { BaseController } from "../../utilities/base_controller";
import { getScrollParent, scrollAbsoluteBottom } from "../../utilities/scroll";
export class ScrollToBottomController extends BaseController {
    static values = {
        mode: String,
    };
    get _mode() {
        return this.hasModeValue ? this.modeValue : "document";
    }
    get _scrollTarget() {
        let target;
        if (this._mode == "document") {
            target = window;
        }
        else {
            target = getScrollParent(this.el);
        }
        return target;
    }
    async scroll(event) {
        event?.preventDefault();
        if (this._scrollTarget) {
            await scrollAbsoluteBottom(this._scrollTarget);
        }
    }
}
