import { BaseController } from "../../utilities/base_controller";
import { extractPredicates } from "./expressions";
import { EventBus } from "../../utilities/event_bus";
import { signalConnectEvent, signalValueEvent } from "./events";
import { useEventBus } from "../../mixins/use_event_bus";
export class SignalBaseController extends BaseController {
    static values = {
        name: String,
    };
    connect() {
        EventBus.emit(signalConnectEvent(this.nameValue));
        useEventBus(this, signalValueEvent(this.nameValue), this._onSignal);
    }
    get predicateString() {
        return "";
    }
    get _predicates() {
        return extractPredicates(this.predicateString);
    }
    allPredicatesMatch(value) {
        return this._predicates.every(predicate => predicate(value));
    }
}
